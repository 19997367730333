import Promise from 'bluebird';

const requestMethod = {
    get: 'GET',
    post: 'POST',
    put: 'PUT',
    deleteHttp: 'DELETE'
};

function request(url, method, data) {
    const createHeaders = () => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json;charset=UTF-8');

        return headers;
    };

    const sendRequest = () => {
        return fetch(url, {
            method: method,
            headers: createHeaders(),
            body: data ? JSON.stringify(data) : undefined
        });
    };

    const parseResponse = response => {
        return response.text().then(text => {
            try {
                return JSON.parse(text);
            } catch (ex) {
                return text;
            }
        });
    };

    const checkResponse = async response => {
        const createError = (message, body) => ({
            url: url,
            message: message,
            status: response.status,
            statusText: response.statusText,
            body: body
        });

        if (!response.ok) {
            return Promise.reject(createError('Response nok.', await parseResponse(response)));
        } else {
            return response;
        }
    };

    return sendRequest().then(checkResponse).then(parseResponse);
}

export default {
    get(url) {
        return request(url, requestMethod.get);
    },

    post(url, data) {
        return request(url, requestMethod.post, data);
    },

    put(url, data) {
        return request(url, requestMethod.put, data);
    },

    deleteHttp(url) {
        return request(url, requestMethod.deleteHttp);
    }
};