import {Checkbox, FormControlLabel, InputBase, RadioGroup, TextField} from '@material-ui/core';
import {Component, createElement} from 'react';

/** Helper functions **/
const isStateLess = Component => !Component.prototype || !Component.prototype.render;

const mapError = (
    {
        hasHelperText = true,
        meta: {touched, error, warning} = {},
        input,
        ...props
    }
) => {
    const errorProps = touched && (error || warning) ?
        {...props, ...input, error: Boolean(error || warning)} :
        {...input, ...props};

    if (touched && hasHelperText && (error || warning)) {
        errorProps.helperText = error || warning;
    }

    return errorProps;
};

const createComponent = (MaterialUIComponent, mapProps) => {
    class WrappedComponent extends Component {
        getRenderedComponent() {
            return this.component;
        }

        render() {
            return createElement(MaterialUIComponent, {
                ...mapProps(this.props),
                ref: (!isStateLess(MaterialUIComponent) ? el => this.component = el : null)
            });
        }
    }

    WrappedComponent.displayName = `ReduxFormMaterialUI${MaterialUIComponent.displayName}`;

    return WrappedComponent;
};

/** Wrappers **/
export const WrappedTextField = createComponent(TextField, ({defaultValue, ...props}) => mapError(props));

export const WrappedInputBase = createComponent(
    InputBase,
    ({
         input: {onChange, ...inputProps},
         onChange: ignoredOnChange,
         ...props
     }) => ({
        ...inputProps,
        ...props,
        onChange: event => onChange(event)
    })
);

export const WrappedCheckbox = createComponent(
    Checkbox,
    ({
         input: {onChange, value, ...inputProps},
         meta,
         onChange: ignoredOnChange,
         defaultChecked,
         ...props
     }) => ({
        ...inputProps,
        ...props,
        checked: !!value,
        onChange: (event, isInputChecked) => onChange(isInputChecked)
    })
);

export const WrappedFormControlLabel = createComponent(
    FormControlLabel,
    ({
         input: {onChange, value},
         meta,
         onChange: onChangeFromField,
         ...props
     }
    ) => ({
        onChange,
        checked: !!value,
        ...props
    })
);

export const WrappedRadioGroup = createComponent(
    RadioGroup,
    ({
         input: {onChange, value, ...inputProps},
         meta,
         onChange: onChangeFromField,
         ...props
     }) => ({
        ...inputProps,
        ...props,
        value,
        onChange: (event, value) => {
            onChange(value);
            if (onChangeFromField) {
                onChangeFromField(value);
            }
        }
    })
);
