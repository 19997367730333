import {
    Button,
    CircularProgress,
    Collapse,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography
} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {WrappedCheckbox, WrappedTextField} from 'fragements/form/reduxFormMaterialUi';
import FormHelper, {maxLength, required} from 'fragements/FormHelper';
import {AddIcon, DeleteIcon, TranslationActions} from 'fragements/mini-components';
import ModalEnabledComponent from 'fragements/ModalEnabledComponent';
import NewTranslationFrom from 'pages/admin/parts/NewTranslationFrom';
import * as PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {Field} from 'redux-form';
import styled from 'styled-components';
import AuthenticationNeeded from '../../fragements/AuthenticationNeeded';
import {
    addOrUpdateTranslations,
    createNewLanguage,
    deleteLanguage,
    initialFullDataLoad,
    updateLanguage
} from '../../redux/translation.actions';
import {onAddTranslation} from './functions/translation-functions';
import EditTranslation from './parts/EditTranslation';

const maxLength2 = maxLength(2);

class TranslationAdminPage extends ModalEnabledComponent {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            expandedTreeNode: '',
            modifying: false
        };
        this.onAddTranslation = onAddTranslation.bind(this);
    }

    onNewTranslation = (translations) => {
        this.setState({modifying: true});
        this.onAddTranslation(translations);
        this.setState({modifying: false});
    };

    onDeleteLanguage = languageKey => this.openModal({
        type: 'question',
        question: `Do you really want to delete the entire language with key '${languageKey}' and all its translations?`,
        callback: props => props.ok && this.props.deleteLanguage(languageKey).catch(this.handleError),
        okText: 'Delete'
    });

    mapLanguages() {
        return this.props.supportedLanguages.map(language => {
            const {languageKey, languageName} = language;

            return (
                <StyledAccordion key={`languageEditor-${languageKey}`}>
                    <AccordionSummary expandIcon={<ExpandMore/>}>
                        <Typography>{languageName} ({languageKey})</Typography>
                    </AccordionSummary>
                    <StyledAccordionDetails>
                        <AuthenticationNeeded hideProgress hasRole="atara-i18n-backend:translation_admin">
                            <TranslationActions>
                                <Button color="secondary"
                                        onClick={this.onDeleteLanguage(languageKey)}>
                                    <DeleteIcon/>
                                    Delete Language
                                </Button>
                            </TranslationActions>
                        </AuthenticationNeeded>
                        {this.languageForm(language)}
                    </StyledAccordionDetails>
                </StyledAccordion>
            );
        });
    }

    languageForm(existingLanguage) {
        const onCreateNewLanguage = newLanguage => this.props.createNewLanguage(newLanguage.languageKey, {
            languageName: newLanguage.languageName,
            languageActive: newLanguage.languageActive
        }).then(this.closeModal).catch(this.handleError);

        const onUpdateLanguage = updatedLanguage => this.props.updateLanguage(existingLanguage.languageKey, updatedLanguage)
            .then(this.closeModal).catch(this.handleError);

        const updateMode = !!existingLanguage;
        const formOptions = {
            onSubmit: updateMode ? onUpdateLanguage : onCreateNewLanguage,
            form: updateMode ? `updateLanguage-${existingLanguage.languageKey}` : 'newLanguage',
            columns: updateMode ? 2 : 1,
            modifying: this.state.modifying
        };

        return (
            <FormHelper {...formOptions} initialValues={existingLanguage}>
                <Field key={`${formOptions.form}-languageKey`} name="languageKey" component={WrappedTextField}
                       label="Language Key" validate={[required, maxLength2]}/>
                <Field key={`${formOptions.form}-languageName`} name="languageName" component={WrappedTextField}
                       label="Language Name" validate={required}/>
                <FormControlLabel label="UI Language" control={<Field name="languageActive" component={WrappedCheckbox}/>}/>
                <FormControlLabel label="User Content Language" control={<Field name="languageActiveForUserContent" component={WrappedCheckbox}/>}/>
            </FormHelper>
        );
    }

    isTreeNodeExpanded(key) {
        return !!this.state.expandedTreeNode && this.state.expandedTreeNode.startsWith(key);
    }

    createTree(translations = this.props.translations, keyPrefix = '', level = 0) {
        if (!translations || translations.length === 0) {
            return <div>No translations found...</div>;
        }

        return (
            <List component="div" disablePadding dense>
                {Object.keys(translations).sort().map(translationKey => {
                    const fullKey = keyPrefix ? `${keyPrefix}.${translationKey}` : translationKey;
                    const children = translations[translationKey].subMessages;
                    const hasChildren = !!children;

                    return (
                        <Fragment key={fullKey}>
                            <SubListItem
                                key={`${fullKey}-translations`}
                                button
                                level={level}
                                selected={fullKey === this.props.translationKey}
                                component={Link}
                                to={`/admin/${fullKey}`}
                            >
                                <ListItemText primary={translationKey}/>
                                {hasChildren && <>{this.isTreeNodeExpanded(fullKey) ? <ExpandLess/> : <ExpandMore/>}</>}
                            </SubListItem>
                            {hasChildren &&
                            <Collapse key={`${fullKey}-subMessages`} in={this.isTreeNodeExpanded(fullKey)}
                                      timeout="auto" unmountOnExit>
                                {this.createTree(children, fullKey, level + 1)}
                            </Collapse>
                            }
                        </Fragment>
                    );
                })}
            </List>
        );
    }

    allLoaded = () => Object.keys(this.props.supportedLanguages).length > 0 && Object.keys(this.props.translations).length > 0;

    render() {
        if (!this.allLoaded()) {
            return <CircularProgress size={50} disableShrink/>;
        } else {
            return (
                <Fragment>
                    {super.render()}
                    <ContentContainer>
                        <TranslationActions>
                            <Button
                                disabled={!this.props.supportedLanguages || this.props.supportedLanguages.length === 0}
                                color="primary" onClick={this.openModal({
                                type: 'translation',
                                content: <NewTranslationFrom
                                    translationsKey={''}
                                    supportedLocales={this.props.supportedLocales}
                                    onSubmit={this.onNewTranslation}
                                />
                            })}>
                                <AddIcon/>
                                Add root translation
                            </Button>
                        </TranslationActions>

                        <TranslationContainer>
                            <TreeViewContainer>
                                {this.createTree()}
                            </TreeViewContainer>
                            <TranslationAdministrationContainer>
                                {this.props.translationKey ?
                                    <EditTranslation translationKey={this.props.translationKey}/> :
                                    <div>Choose translation in the tree...</div>}
                            </TranslationAdministrationContainer>
                        </TranslationContainer>

                        <Typography variant="h5" gutterBottom>Languages</Typography>
                        {this.mapLanguages()}
                        <TranslationActions>
                            <Button onClick={this.openModal({
                                type: 'language',
                                content: this.languageForm()
                            })}>
                                <AddIcon/>
                                Add new language
                            </Button>
                        </TranslationActions>
                    </ContentContainer>
                </Fragment>
            );
        }
    }

    componentDidMount() {
        this.props.initialDataLoad().catch(this.handleError);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname === '/admin' && this.allLoaded()) {
            this.props.history.replace(`/admin/${Object.keys(this.props.translations).sort()[0]}`);
        }

        if (this.allLoaded() && (
            (this.props.location.pathname !== '/admin' && !this.state.expandedTreeNode) ||
            this.props.location.pathname !== prevProps.location.pathname
        )) {
            this.setState({expandedTreeNode: this.props.translationKey});
        }
    }
}

TranslationAdminPage.propTypes = {
    translations: PropTypes.object.isRequired,
    supportedLanguages: PropTypes.array.isRequired,
    supportedLocales: PropTypes.array.isRequired,
    translationsKey: PropTypes.string
};

const ContentContainer = styled.div`
  display: block;
  width: 100%;
  max-width: 1200px;
  align-self: flex-start;
`;

const TranslationContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 32px;
  height: 450px;
`;

const TreeViewContainer = styled(Paper)`
  min-width: 250px;
  width: 300px;
  height: 100%;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 4px;
  }
 
  &::-webkit-scrollbar-track {
    box-shadow: none
  }
 
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 2px;
  }
`;

const TranslationAdministrationContainer = styled.div`
  display: flex;
  flex: 1;
  margin-left: 16px;
  height: 100%;
`;

const SubListItem = styled(ListItem)`
  padding-left: ${props => (props.level || 0) * 16 + 16}px !important;
`;

const StyledAccordion = styled(Accordion)`
  width: 100%;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
`;

const mapStateToProps = (state, ownProps) => {
    return {
        translations: state.translation.translations,
        supportedLanguages: state.translation.supportedLanguages.sort(),
        supportedLocales: state.translation.supportedLanguages.map(language => language.languageKey),
        translationKey: ownProps.match.params.translationKey
    };
};

const mapDispatchToProps = {
    initialDataLoad: initialFullDataLoad,
    addOrUpdateTranslations,
    deleteLanguage,
    createNewLanguage,
    updateLanguage
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TranslationAdminPage));