import {SET_ALL_FLAT_TRANSLATIONS, SET_ALL_SUPPORTED_LANGUAGES, SET_ALL_TRANSLATIONS} from './translation.actions';

const initialState = {
    translations: {},
    flatTranslations: [],
    supportedLanguages: []
};

const translation = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_TRANSLATIONS:
            return Object.assign({}, state, {translations: action.translations});
        case SET_ALL_FLAT_TRANSLATIONS:
            return Object.assign({}, state, {flatTranslations: action.flatTranslations});
        case SET_ALL_SUPPORTED_LANGUAGES:
            return Object.assign({}, state, {supportedLanguages: action.supportedLanguages});
        default:
            return state;
    }
};

export default translation;