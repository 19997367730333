import InfoModal from './InfoModal';
import React, {Component} from 'react';

export default class ModalEnabledComponent extends Component {

    constructor(props){
        super(props);

        this.state = {
            modalOpen: false,
            modalOptions: {}
        };
    }

    openModal = modalOptions => () => {
        this.setState({modalOpen: true, modalOptions});
    };

    closeModal = () => {
        this.setState({modalOpen: false, modalOptions: {}});
    };

    handleError = error => this.openModal({type: 'error', error})();

    render() {
        return (
            <InfoModal open={this.state.modalOpen}
                       onClose={this.closeModal}
                       modalOptions={this.state.modalOptions}/>
        );
    }
}