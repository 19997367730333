import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './root.reducer';
import throttle from 'lodash/throttle';

const stateKey = 'atara-i18n-state';
const securityKey = 'security'; // Needs to be identical in all web clients!

let store;

const loadState = () => {
    const savedState = localStorage.getItem(stateKey);
    const savedSecurity = localStorage.getItem(securityKey);

    if (savedState === null) {
        return undefined;
    }

    const composedState = Object.assign(
        {},
        savedState ? JSON.parse(savedState) : {},
        savedSecurity ? {security: JSON.parse(savedSecurity)} : {}
    );

    return Object.keys(composedState).length > 0 ? composedState : undefined;
};

const saveState = () => {
    const {translation, security} = store.getState();

    localStorage.setItem(stateKey, JSON.stringify({
        translation
    }));

    localStorage.setItem(securityKey, JSON.stringify({
        token: security.token,
        refreshToken: security.refreshToken,
        idToken: security.idToken,
        timeSkew: security.timeSkew
    }));
};

const setupStore = () => {
    const persistedState = loadState();
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(thunk)));

    store.subscribe(throttle(saveState, 1000)); // Make sure the 'saveState' method is only called once a second

    return store;
};

export default setupStore;