import Promise from 'bluebird';
import xhr from 'util/xhr';

const baseApiUrl = '/api';

export const SET_ALL_TRANSLATIONS = 'SET_ALL_TRANSLATIONS';
export const SET_ALL_FLAT_TRANSLATIONS = 'SET_ALL_FLAT_TRANSLATIONS';
export const SET_ALL_SUPPORTED_LANGUAGES = 'SET_ALL_SUPPORTED_LANGUAGES';

export const initialFullDataLoad = () => dispatch => Promise.all([
    dispatch(loadAllSupportedLanguages()),
    dispatch(loadAllTranslationsFlat()),
    dispatch(loadAllTranslations())
]);

export const initialFlatDataLoad = () => dispatch => Promise.all([
    dispatch(loadAllSupportedLanguages()),
    dispatch(loadAllTranslationsFlat())
]);

export const loadAllTranslations = () => dispatch => xhr.get(`${baseApiUrl}/language`)
    .then(translations => dispatch(setAllTranslations(translations)));

export const loadAllTranslationsFlat = () => dispatch => xhr.get(`${baseApiUrl}/language/flat`)
    .then(flatTranslations => dispatch(setAllFlatTranslations(flatTranslations)));

export const addOrUpdateTranslations = (translationKey, translations) => dispatch => Promise.all([
    isNotEmpty(translations.toUpdate) ? xhr.put(`${baseApiUrl}/translations/${translationKey}`, translations.toUpdate) : Promise.resolve(),
    isNotEmpty(translations.toAdd) ? xhr.post(`${baseApiUrl}/translations/${translationKey}`, translations.toAdd) : Promise.resolve()
])
    .then(() => dispatch(loadAllTranslations()))
    .then(() => dispatch(loadAllTranslationsFlat()));

export const deleteTranslations = translationKey => dispatch => xhr.deleteHttp(`${baseApiUrl}/translations/${translationKey}`)
    .then(() => dispatch(loadAllTranslations()))
    .then(() => dispatch(loadAllTranslationsFlat()));

export const loadAllSupportedLanguages = () => dispatch => xhr.get(`${baseApiUrl}/supportedLanguages`)
    .then(supportedLanguages => dispatch(setAllSupportedLanguages(supportedLanguages)));

export const createNewLanguage = (languageKey, languageProps) => dispatch => xhr.post(`${baseApiUrl}/language/${languageKey}`, languageProps)
    .then(() => dispatch(loadAllSupportedLanguages()));

export const updateLanguage = (languageKey, languageProps) => dispatch => xhr.put(`${baseApiUrl}/language/${languageKey}`, languageProps)
    .then(() => dispatch(initialFullDataLoad()));

export const deleteLanguage = languageKey => dispatch => xhr.deleteHttp(`${baseApiUrl}/language/${languageKey}`)
    .then(() => dispatch(initialFullDataLoad()));


const setAllTranslations = translations => ({
    type: SET_ALL_TRANSLATIONS,
    translations
});

const setAllFlatTranslations = flatTranslations => ({
    type: SET_ALL_FLAT_TRANSLATIONS,
    flatTranslations
});

const setAllSupportedLanguages = supportedLanguages => ({
    type: SET_ALL_SUPPORTED_LANGUAGES,
    supportedLanguages
});

const isNotEmpty = obj => {
    return obj && Object.keys(obj).length > 0;
};