import {Button, CircularProgress, Paper, Typography} from '@material-ui/core';
import AuthenticationNeeded from 'fragements/AuthenticationNeeded';
import CopyButtons from 'fragements/CopyButtons';
import {WrappedTextField} from 'fragements/form/reduxFormMaterialUi';
import FormHelper from 'fragements/FormHelper';
import {AddIcon, DeleteIcon, TranslationActions} from 'fragements/mini-components';
import ModalEnabledComponent from 'fragements/ModalEnabledComponent';
import {onAddTranslation} from 'pages/admin/functions/translation-functions';
import NewTranslationFrom from 'pages/admin/parts/NewTranslationFrom';
import * as PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Field} from 'redux-form';
import {addOrUpdateTranslations, deleteTranslations, initialFlatDataLoad} from 'redux/translation.actions';
import styled from 'styled-components';

class EditTranslation extends ModalEnabledComponent {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            modifying: false
        };
        this.onAddTranslation = onAddTranslation.bind(this);
    }

    onNewTranslation = (translations) => {
        this.setState({modifying: true});
        this.onAddTranslation(translations);
        this.setState({modifying: false});
    };

    onDeleteTranslations = () => this.openModal({
        type: 'question',
        question: `Do you really want to delete translation with key '${this.props.translationKey}'?`,
        callback: props => {
            this.setState({modifying: true});

            return props.ok && this.props.deleteTranslations(this.props.translationKey).catch(this.handleError)
                .then(() => {
                    if (this.props.translationKey.indexOf('.') >= 0) {
                        this.props.history.replace(`/admin/${this.props.translationKey.substring(0, this.props.translationKey.lastIndexOf('.'))}`);
                    } else {
                        this.props.history.replace('/admin');
                    }
                })
                .finally(() => this.setState({modifying: false}));
        },
        okText: 'Delete'
    });

    onSaveTranslations = changedTranslations => {
        this.setState({modifying: true});

        const splitTranslations = {toUpdate: {}, toAdd: {}};
        this.props.supportedLocales.forEach(languageKey => {
            if (this.props.translation && this.props.translation[languageKey]) {
                splitTranslations.toUpdate[languageKey] = changedTranslations[languageKey];
            } else {
                splitTranslations.toAdd[languageKey] = changedTranslations[languageKey];
            }
        });

        this.props.addOrUpdateTranslations(this.props.translationKey, splitTranslations)
            .catch(this.handleError)
            .finally(() => this.setState({modifying: false}));
    };

    render() {
        if (!this.props.translationsLoaded) {
            return <CircularProgress size={50} disableShrink/>;
        } else {
            const uniqueFormName = this.props.translationKey.replace(/\./g, '-');

            return (
                <>
                    {super.render()}
                    <EditContainer>
                        <Typography variant="h5" gutterBottom>{this.props.translationKey}</Typography>

                        <TranslationActions>
                            <Button
                                color="primary" onClick={this.openModal({
                                type: 'translation',
                                translationPrefix: this.props.translationKey,
                                content: <NewTranslationFrom
                                    translationKey={this.props.translationKey}
                                    supportedLocales={this.props.supportedLocales}
                                    onSubmit={this.onNewTranslation}
                                />
                            })}
                            >
                                <AddIcon/>
                                Add sub-translation
                            </Button>
                            {this.props.translation &&
                            <>
                                <AuthenticationNeeded hideProgress hasRole="atara-i18n-backend:translation_admin">
                                    <Button
                                        color="secondary"
                                        onClick={this.onDeleteTranslations()}
                                        disabled={this.state.modifying}
                                    >
                                        <DeleteIcon/>
                                        Delete
                                        {(this.state.modifying) && <SubmitLoadingSpinner size={20}/>}
                                    </Button>
                                </AuthenticationNeeded>
                                <CopyButtons translationKey={this.props.translationKey}/>
                            </>}
                        </TranslationActions>

                        <FormHelper
                            onSubmit={this.onSaveTranslations}
                            modifying={this.state.modifying}
                            form={uniqueFormName}
                            key={uniqueFormName}
                            initialValues={this.props.translation}
                            columns={1}
                        >
                            {this.props.supportedLocales.map(languageKey =>
                                <Field
                                    key={`${languageKey}`}
                                    name={languageKey}
                                    label={languageKey}
                                    multiline
                                    component={WrappedTextField}
                                />
                            )}
                        </FormHelper>
                    </EditContainer>
                </>
            );
        }
    }

    componentDidMount() {
        this.props.initialFlatDataLoad().catch(this.handleError);
    }
}

EditTranslation.propTypes = {
    translationKey: PropTypes.string.isRequired,
    translationsLoaded: PropTypes.bool.isRequired,
    supportedLocales: PropTypes.array.isRequired,
    translation: PropTypes.object
};

const EditContainer = styled(Paper)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  overflow: auto;
`;

const SubmitLoadingSpinner = styled(CircularProgress)`
  display: inline-block;
  margin-left: 5px;
`;

const mapStateToProps = (state, ownProps) => ({
    translation: state.translation.flatTranslations.find(translation => translation.translationKey === ownProps.translationKey),
    translationsLoaded: state.translation.flatTranslations.length > 0,
    supportedLocales: state.translation.supportedLanguages.map(language => language.languageKey)
});

const mapDispatchToProps = {
    initialFlatDataLoad,
    addOrUpdateTranslations,
    deleteTranslations
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditTranslation));