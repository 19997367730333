import {Button, Modal, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import styled from 'styled-components';

class InfoModal extends Component {

    translationModalContent = modalOptions => (
        <Fragment>
            <Typography variant="h5">{modalOptions.translationPrefix ? `Add translation under '${modalOptions.translationPrefix}'` : 'Add new root translation'}</Typography>
            <MainContent>
                {modalOptions.content}
            </MainContent>
        </Fragment>
    );

    languageModalContent = modalOptions => (
        <Fragment>
            <Typography variant="h5">Add new language</Typography>
            <MainContent>
                {modalOptions.content}
            </MainContent>
        </Fragment>
    );

    errorModalContent = modalOptions => (
        <Fragment>
            <Typography variant="h5">Oops, something went wrong...</Typography>
            <MainContent>
                <Typography>
                    {modalOptions.error.status}: {modalOptions.error.statusText}<br/><br/>
                    {modalOptions.error.body || ''}
                </Typography>
            </MainContent>
            <BottomButtonContainer>
                <Button color="primary" onClick={this.props.onClose}>Close</Button>
            </BottomButtonContainer>
        </Fragment>
    );

    questionModalContent = modalOptions => (
        <Fragment>
            <Typography variant="h5">Caution!</Typography>
            <MainContent>
                <Typography>{modalOptions.question}</Typography>
            </MainContent>
            <BottomButtonContainer>
                <Button color="primary" onClick={this.onCloseWithProps(modalOptions.onClose,{ok: false})}>
                    {modalOptions.cancelText || 'Cancel'}
                </Button>
                <Button color="primary" variant="contained" onClick={this.onCloseWithProps(modalOptions.callback,{ok: true})}>
                    {modalOptions.okText || 'OK'}
                </Button>
            </BottomButtonContainer>
        </Fragment>
    );

    onCloseWithProps = (callback, props) => () => {
        this.props.onClose();
        callback && callback(props);
    };

    render() {
        const {open, onClose, modalOptions} = this.props;

        return (
            <Modal
                open={open}
                onClose={modalOptions.type !== 'question' ? onClose : undefined}
                disableBackdropClick={modalOptions.type === 'question'}
            >
                <ModalContent>
                    {modalOptions.type === 'translation' && this.translationModalContent(modalOptions)}
                    {modalOptions.type === 'language' && this.languageModalContent(modalOptions)}
                    {modalOptions.type === 'error' && this.errorModalContent(modalOptions)}
                    {modalOptions.type === 'question' && this.questionModalContent(modalOptions)}
                </ModalContent>
            </Modal>
        );
    }
}

InfoModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    modalOptions: PropTypes.object.isRequired
};

const ModalContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  width: 40vw;
  min-height: 30vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 10px 10px 31px rgba(0,0,0,0.6);
  outline: none;
`;

const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  
  & > Button:not(:first-child) {
    margin-left: 16px;
  }
`;

const MainContent = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export default InfoModal;