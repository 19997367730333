import {WrappedTextField} from 'fragements/form/reduxFormMaterialUi';
import FormHelper, {required} from 'fragements/FormHelper';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';

class NewTranslationForm extends Component {

    render() {
        return (
            <FormHelper
                onSubmit={this.props.onSubmit}
                modifying={this.props.saving}
                form="newTranslation"
                columns={1}
                initialValues={{translationKey: this.props.translationKey ? `${this.props.translationKey}.` : undefined}}
            >
                <Field
                    name="translationKey"
                    label="Translation key"
                    component={WrappedTextField}
                    validate={required}
                />

                {this.props.supportedLocales.map(languageKey => (
                    <Field
                        key={`addTranslation-${languageKey}`}
                        name={languageKey}
                        label={languageKey}
                        multiline
                        component={WrappedTextField}
                    />
                ))}
            </FormHelper>
        );
    }
}

const mapStateToProps = state => {
    return ({
        saving: state.form.newTranslation ? state.form.newTranslation.submitSucceeded : false
    });
};

NewTranslationForm.propTypes = {
    supportedLocales: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    translationsKey: PropTypes.string
};

export default connect(mapStateToProps)(NewTranslationForm);