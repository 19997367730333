import fetchIntercept from 'fetch-intercept';
import Keycloak from 'keycloak-js';
import {clientName, clientSubDomain, realmName} from 'util/general-configuration';

export const UPDATE = 'UPDATE';
export const RESET_SECURITY = 'RESET_SECURITY';
export const RESET_TOKENS = 'RESET_TOKENS';
export const SET_PROFILE = 'SET_PROFILE';

const refreshIntervalInMs = 10000;
const minTokenValidityInSeconds = refreshIntervalInMs * 1.5 / 1000;

const getAuthenticationUrl = () => {
    const location = window.location.href;

    const result = location.match('^(?:https?://)?(?:[^@/\n]+@)?(?:www.)?([^/?\n]+)');
    const extractedDomain = result[1];

    if (extractedDomain === undefined) {
        throw Error('Could not extract Domain');
    } else {
        if (extractedDomain.startsWith('localhost')) {
            return 'http://localhost:8081/auth'
        } else {
            return 'https://' + extractedDomain.replace(clientSubDomain, 'id') + '/auth';
        }
    }
};

const keycloakConfig = {
    url: getAuthenticationUrl(),
    realm: realmName,
    clientId: clientName,
    'public-client': true,
    'ssl-required': 'external'
};

const keycloak = new Keycloak(keycloakConfig);

export const hasRole = role => {
    const resourceRole = role.split(':');
    return keycloak.hasResourceRole(resourceRole[1], resourceRole[0]);
};

let initialized = false;
let unregisterTokenInterceptor;
let updateIntervalId;

export const initSecurity = (token, refreshToken, idToken, timeSkew) => dispatch => {
    if (initialized) {
        console.warn('Keycloak has already been initialized!');
        return;
    }

    keycloak.init({onLoad: 'login-required', token, refreshToken, idToken, timeSkew}).then(authenticated => {
        if (authenticated) {
            initialized = true;

            keycloak.loadUserProfile().then(profile => dispatch(setProfile(profile)));

            updateIntervalId = createUpdateInterval(dispatch);
            unregisterTokenInterceptor = addTokenToRequests();

            dispatch(update());
        }
    });

    // Always delete tokens during initialization since it leads to an infinity-loop if the stored token was expired initially...
    dispatch(resetTokens());
};

export const logout = () => {
    keycloak.logout();

    if (unregisterTokenInterceptor) {
        unregisterTokenInterceptor();
    }

    if (updateIntervalId) {
        clearInterval(updateIntervalId);
    }

    return resetSecurity();
};

const resetSecurity = () => ({
    type: RESET_SECURITY
});

const resetTokens = () => ({
    type: RESET_TOKENS
});

const update = () => ({
    type: UPDATE,
    keycloak
});

const setProfile = profile => ({
    type: SET_PROFILE,
    profile
});

const createUpdateInterval = dispatch => setInterval(() => {
    keycloak.updateToken(minTokenValidityInSeconds)
        .then(refreshed => {
            if (refreshed) {
                dispatch(update());
            }
        })
        .catch(error => {
            console.error('Could not update token.', error);
        });
}, refreshIntervalInMs);

const addTokenToRequests = () => fetchIntercept.register({
    request: (url, config) => {
        if (!config.headers) {
            config.headers = new Headers();
        }
        config.headers.append('Authorization', `bearer ${keycloak.token}`);

        return [url, config];
    }
});