import {CssBaseline} from '@material-ui/core';
import React, {Component, Fragment} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {createGlobalStyle} from 'styled-components';
import MainPage from './pages/MainPage';
import 'typeface-roboto';

class App extends Component {
    render() {
        return (
            <Fragment>
                <CssBaseline/>
                <GlobalStyle/>
                <BrowserRouter>
                    <MainPage/>
                </BrowserRouter>
            </Fragment>
        );
    }
}

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    position: relative;
  }

  body {
    margin: 0;
    min-height: 100vh;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    
    p {
        hyphens: auto;
        hyphenate-limit-chars: auto;
        hyphenate-limit-lines: 2;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }
  }
`;

export default App;