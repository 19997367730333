import {Button, CircularProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {reduxForm} from 'redux-form';
import styled from 'styled-components';

export const required = value => value ? undefined : 'Required';
export const maxLength = maxLength => value => value.length <= maxLength ? undefined : `Max length is ${maxLength}`;

class FormHelper extends Component {
    render() {
        const {
            reset,
            handleSubmit,
            submitting,
            modifying,
            invalid,
            pristine,
            className,
            children,
            submitButtonMessage,
            resetButtonMessage,
            additionalOnReset,
            buttonVariant,
            switchButtonOrder
        } = this.props;

        const resetButtonClicked = () => {
            reset();

            if (additionalOnReset) {
                additionalOnReset();
            }
        };

        return (
            <StyledFrom onSubmit={handleSubmit} className={className}>
                <FieldBox className="bubble-buddy-form--field-box" columns={this.props.columns}>
                    {children}
                </FieldBox>

                <ButtonBox className="bubble-buddy-form--button-box">
                    <Button className="bubble-buddy-form--reset-button"
                            onClick={resetButtonClicked}
                            disabled={pristine || submitting || modifying}
                            variant={buttonVariant || 'text'}>
                        {resetButtonMessage || 'Reset'}
                    </Button>
                    <Button className={`bubble-buddy-form--submit-button${switchButtonOrder ? ' first' : ''}`}
                            color="primary" type="submit"
                            disabled={invalid || pristine || submitting || modifying}
                            variant={buttonVariant || 'contained'}>
                        {submitButtonMessage || 'Save'}
                        {(submitting || modifying) && <SubmitLoadingSpinner size={20}/>}
                    </Button>
                </ButtonBox>
            </StyledFrom>
        );
    }
}

const StyledFrom = styled.form`
  width: 100%;
`;

const FieldBox = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(${props => props.columns || 4}, 1fr);
  align-content: center;

  & > * {
    min-height: 70px; // Height of a form field with error text
  }
`;

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  bottom: 0;
  background-color: white;
  
  .first {
    order: -1;
  }
  
  button {
    margin: 24px 12px 0;
    
    &:last-child {
      margin-right: 0;
    }
    
    &:first-child {
      margin-left: 0;
    }
  }
`;

const SubmitLoadingSpinner = styled(CircularProgress)`
  display: inline-block;
  margin-left: 5px;
`;

FormHelper.propTypes = {
    form: PropTypes.string.isRequired,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    submitButtonMessage: PropTypes.string,
    resetButtonMessage: PropTypes.string,
    additionalOnReset: PropTypes.func,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    buttonVariant: PropTypes.string,
    switchButtonOrder: PropTypes.bool,
    columns: PropTypes.number,
    modifying: PropTypes.bool
};

export default reduxForm({enableReinitialize: true})(FormHelper);
