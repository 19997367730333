import {CircularProgress} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {hasRole, initSecurity} from 'redux/security.actions';

class AuthenticationNeeded extends Component {

    constructor(props) {
        super(props);

        if (!props.securityInitialized) {
            const {token, refreshToken, idToken, timeSkew} = props;
            props.dispatch(initSecurity(token, refreshToken, idToken, timeSkew));
        }
    }

    isAllowedToSeeContent() {
        if (!this.props.securityInitialized || !this.props.authenticated) {
            return false;
        }

        if (this.props.hasRole) {
            return hasRole(this.props.hasRole);
        }

        return true;
    }

    render() {
        if (this.props.hideProgress && !this.props.securityInitialized) {
            return null;
        } else if (!this.props.securityInitialized) {
            return <CircularProgress size={this.props.progressSize || 50} disableShrink={!this.props.progressEnableShrink}/>;
        } else if (this.isAllowedToSeeContent()) {
            return this.props.children;
        } else if (this.props.notAuthorized) {
            return this.props.notAuthorized;
        }

        return null; // Render nothing
    }
}

AuthenticationNeeded.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    notAuthorized: PropTypes.element,
    hasRole: PropTypes.string,
    hideProgress: PropTypes.bool,
    progressSize: PropTypes.number,
    progressEnableShrink: PropTypes.bool
};

const mapStateToProps = state => ({
    securityInitialized: state.security.initialized,
    authenticated: state.security.authenticated,
    token: state.security.token,
    refreshToken: state.security.refreshToken,
    idToken: state.security.idToken,
    timeSkew: state.security.timeSkew
});

export default connect(mapStateToProps)(AuthenticationNeeded);