import {CircularProgress} from '@material-ui/core';
import CopyButtons from 'fragements/CopyButtons';
import ModalEnabledComponent from 'fragements/ModalEnabledComponent';
import MaterialTable from 'material-table';
import * as PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {hasRole} from 'redux/security.actions';
import {addOrUpdateTranslations, initialFlatDataLoad} from 'redux/translation.actions';
import styled from 'styled-components';
import {tableIcons} from 'util/table-icons';

class TranslationTablePage extends ModalEnabledComponent {

    translationsKeyRenderer(rowData) {
        if (hasRole('atara-i18n-backend:translation_admin')) {
            return (
                <TranslationKeyCell>
                    <Link to={`/admin/${rowData.translationKey}`}>{rowData.translationKey}</Link>

                    <div className="buttons">
                        <CopyButtons translationKey={rowData.translationKey}/>
                    </div>
                </TranslationKeyCell>
            );
        } else {
            return rowData.translationKey;
        }
    }

    translationRenderer(locale) {
        return rowData => (
            <TranslationCell className={!rowData[locale] ? 'not-set' : ''}>{rowData[locale]}</TranslationCell>
        );
    }

    onRowUpdate = (newData, oldData) => {
        const splitTranslations = {toUpdate: {}, toAdd: {}};

        this.props.supportedLocales.forEach(languageKey => {
            if (oldData[languageKey]) {
                splitTranslations.toUpdate[languageKey] = newData[languageKey];
            } else {
                splitTranslations.toAdd[languageKey] = newData[languageKey];
            }
        });

        return this.props.addOrUpdateTranslations(oldData.translationKey, splitTranslations)
            .catch(this.handleError);
    };

    allLoaded = () => Object.keys(this.props.supportedLanguages).length > 0 && this.props.flatTranslations.length > 0;

    render() {
        if (!this.allLoaded()) {
            return <CircularProgress size={50} disableShrink/>;
        } else {
            const columns = [{
                field: 'translationKey',
                title: 'Translation Key',
                readonly: true,
                render: this.translationsKeyRenderer
            }].concat(this.props.supportedLanguages.map(lang => ({
                field: lang.languageKey,
                title: lang.languageName,
                render: this.translationRenderer(lang.languageKey)
            })));

            return (
                <Fragment>
                    {super.render()}
                    <ContentContainer>
                        <MaterialTable title="Translations"
                                       columns={columns}
                                       data={this.props.flatTranslations}
                                       icons={tableIcons}
                                       editable={{onRowUpdate: this.onRowUpdate}}
                                       options={{pageSize: 10}}/>
                    </ContentContainer>
                </Fragment>
            );
        }
    }

    componentDidMount() {
        this.props.initialFlatDataLoad().catch(this.handleError);
    }
}

TranslationTablePage.propTypes = {
    flatTranslations: PropTypes.array.isRequired,
    supportedLanguages: PropTypes.array.isRequired
};

const ContentContainer = styled.div`
  display: block;
  width: 100%;
  max-width: 1200px;
  align-self: flex-start;
`;

const TranslationKeyCell = styled.div`
  display: flex;
  width: 100%;
  min-height: 40px;
  align-items: center;
  
  a {
    text-decoration: none;
    color: inherit;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  .buttons {
    display: flex;
    margin-left: auto;
    
    & > *:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

const TranslationCell = styled.span`
  display: flex;
  width: 100%;
  min-height: 40px;
  align-items: center;
  
  &.not-set {
    background-color: rgba(255, 0, 0, .4);
    border-radius: 5px;
  }
`;

const mapStateToProps = state => {
    return {
        flatTranslations: state.translation.flatTranslations,
        supportedLanguages: state.translation.supportedLanguages.sort(),
        supportedLocales: state.translation.supportedLanguages.map(language => language.languageKey)
    };
};

const mapDispatchToProps = {
    initialFlatDataLoad,
    addOrUpdateTranslations
};

export default connect(mapStateToProps, mapDispatchToProps)(TranslationTablePage);