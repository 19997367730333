import {
    AppBar,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from '@material-ui/core';
import {AccountCircle} from '@material-ui/icons';
import AuthenticationNeeded from 'fragements/AuthenticationNeeded';
import TranslationAdminPage from 'pages/admin/TranslationAdminPage';
import TranslationTablePage from 'pages/translation/TranslationTablePage';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Link, Route, Switch, withRouter} from 'react-router-dom';
import {hasRole, logout} from 'redux/security.actions';
import styled from 'styled-components';
import NotFoundPage from './notFound/NotFoundPage';

class MainPage extends Component {

    state = {};

    onMenuOpen = event => this.setState({anchorEl: event.currentTarget});
    onMenuClose = () => this.setState({anchorEl: null});
    onLogout = () => this.props.dispatch(logout());

    notAuthorized = (
        <Card>
            <CardActionArea>
                <AccessDeniedImage component="img" image={`${process.env.PUBLIC_URL}/img/accessDenied.gif`}/>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">Access Denied</Typography>
                    <Typography component="p">
                        You are not allowed to view this page...<br/>
                        If you think this is wrong please get in touch with our <a
                        href="mailto:support@bubblebuddy.com">support team</a>.
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );

    render() {
        return (
            <Fragment>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h5" color="inherit">
                            BubbleBuddy i18n
                        </Typography>

                        {this.props.userProfile &&
                        <Fragment>
                            <AuthenticationNeeded hideProgress hasRole="atara-i18n-backend:translation_admin">
                                <HeaderActionContainer>
                                    {this.props.location.pathname === '/table' ?
                                        <Button color="inherit" component={Link} to={'/admin'}>
                                            Admin View
                                        </Button>
                                        :
                                        <Button color="inherit" component={Link} to={'/table'}>
                                            Translation View
                                        </Button>
                                    }
                                </HeaderActionContainer>
                            </AuthenticationNeeded>

                            <HeaderActionContainer>
                                <Button onClick={this.onMenuOpen} color="inherit">
                                    <AccountCircleIcon/>
                                    {this.props.userProfile.firstName} {this.props.userProfile.lastName}
                                </Button>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={this.state.anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                    open={!!this.state.anchorEl}
                                    onClose={this.onMenuClose}
                                >
                                    <MenuItem onClick={this.props.keycloak.accountManagement}>Profile</MenuItem>
                                    <MenuItem onClick={this.onLogout}>Logout</MenuItem>
                                </Menu>
                            </HeaderActionContainer>
                        </Fragment>
                        }
                    </Toolbar>
                </AppBar>
                <MainContent>
                    <AuthenticationNeeded hasRole="atara-i18n-backend:translator" notAuthorized={this.notAuthorized}>
                        <Switch>
                            {MainPage.isAdmin() && <Route exact path={'/table'} component={TranslationTablePage}/>}
                            {MainPage.isAdmin() &&
                            <Route exact path={'/admin/:translationKey?'} component={TranslationAdminPage}/>}
                            {!MainPage.isAdmin() && <Route exact path={'/'} component={TranslationTablePage}/>}
                            <Route exact path="*" component={NotFoundPage}/>
                        </Switch>
                    </AuthenticationNeeded>
                </MainContent>
            </Fragment>
        );
    }

    static isAdmin() {
        return hasRole('atara-i18n-backend:translation_admin');
    }

    redirectToAdminPage() {
        if (this.props.location.pathname === '/' && MainPage.isAdmin()) {
            this.props.history.replace('/admin');
        }
    }

    componentDidMount() {
        this.redirectToAdminPage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.redirectToAdminPage();
    }
}

const MainContent = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  width: 100vw;
  min-height: calc(100vh - 64px);
  overflow-x: hidden;
  overflow-y: auto;
`;

const AccessDeniedImage = styled(CardMedia)`
  object-fit: cover;
  object-position: top;
  height: 200px;
`;

const HeaderActionContainer = styled.div`
  margin-left: auto;
`;

const AccountCircleIcon = styled(AccountCircle)`
    margin-right: .5em;
`;

const mapStateToProps = state => ({
    keycloak: state.security.keycloak,
    userProfile: state.security.profile
});

export default withRouter(connect(mapStateToProps)(MainPage));