import {Typography} from '@material-ui/core';
import React, {Component} from 'react';

class NotFoundPage extends Component {
    render() {
        return (
            <Typography variant="body1" color="inherit">
                We could not find the page you're looking for... :(
            </Typography>
        );
    }
}

export default NotFoundPage;