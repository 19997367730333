import {AddRounded, CodeRounded, DeleteRounded, VpnKeyRounded} from '@material-ui/icons';
import styled from 'styled-components';

export const TranslationActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  
  margin-bottom: 16px;
  margin-left: -8px;
  margin-right: -8px;
   
  & > * {
    margin: 8px !important;
    
    &:not(:first-child) {
      margin-left: 16px;
    }
  }

  &:first-child {
    margin-top: -8px;
    margin-bottom: 16px;
  }
  
  &:not(:first-child) {
    margin-top: 16px;
  }
`;

export const AddIcon = styled(AddRounded)`
  margin-left: 16px;
`;

export const KeyIcon = styled(VpnKeyRounded)`
  margin-left: 16px;
`;

export const CodeIcon = styled(CodeRounded)`
  margin-left: 16px;
`;

export const DeleteIcon = styled(DeleteRounded)`
  margin-left: 16px;
`;