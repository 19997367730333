import {IconButton, Tooltip} from '@material-ui/core';
import {CodeRounded as CodeIcon, VpnKeyRounded as KeyIcon} from '@material-ui/icons';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

class CopyButtons extends Component {

    render() {
        const {translationKey} = this.props;
        const i18nCompleteWithKey = `this.props.intl.formatMessage({id: '${translationKey}'})`;

        return (
            <>
                <CopyToClipboard text={translationKey}>
                    <Tooltip title="Copy key">
                        <IconButton>
                            <KeyIcon/>
                        </IconButton>
                    </Tooltip>
                </CopyToClipboard>
                <CopyToClipboard text={i18nCompleteWithKey}>
                    <Tooltip title="Copy react-intl code">
                        <IconButton>
                            <CodeIcon/>
                        </IconButton>
                    </Tooltip>
                </CopyToClipboard>
            </>
        );
    }
}

CopyButtons.propTypes = {
    translationKey: PropTypes.string.isRequired
};

export default CopyButtons;