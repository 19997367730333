import {RESET_SECURITY, RESET_TOKENS, SET_PROFILE, UPDATE} from './security.actions';

const initialState = {
    initialized: false,
    authenticated: false,
    keycloak: {},
    profile: {},
    token: '',
    refreshToken: '',
    idToken: '',
    timeSkew: 0
};

const securityReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE:
            const {authenticated, token, refreshToken, idToken, timeSkew} = action.keycloak;
            return Object.assign({}, state, {
                initialized: true,
                authenticated,
                keycloak: action.keycloak,
                token,
                refreshToken,
                idToken,
                timeSkew
            });
        case SET_PROFILE:
            return Object.assign({}, state, {profile: action.profile});
        case RESET_SECURITY:
            return initialState;
        case RESET_TOKENS:
            return Object.assign({}, state, {token: '', refreshToken: '', idToken: ''});
        default:
            return state;
    }
};

export default securityReducer;